import { getWallet, Tezos } from '../wallet.js'

export { changeFA2admin }

// alternative RPC nodes from https://tezostaquito.io/docs/rpc_nodes/
// SmartPy Mainnet https://mainnet.smartpy.io
// Tezos Foundation Mainnet https://rpc.tzbeta.net/
// madfish is the one used by default by Temple wallet

// const Tezos = new TezosToolkit('https://mainnet-node.madfish.solutions');
// const network = 'mainnet'

const FA2_CONTRACT = 'KT1HKXQJo6Jt3Bt13h1fTEqCNov4N4X3w1t8' // on Ithacanet !
// not needed if the current admin is a wallet, needed if admin has been transfered to a minter contract
const CURRENT_MINTER_AS_ADMIN = '' // on Ithacanet ! 
const NEW_MINTER = 'KT1LDYSZC2hpQqR87zrba4R9SQfRq5mS1nty' // on Ithacanet !
const WALLET_ADMIN = 'tz1ahsDNFzukj51hVpW626qH7Ug9HeUVQDNG' // on Ithacanet !

//  Set the minter contract as the admin of the FA2 contract
//     fa2.transfer_administrator(minter.address)
//     minter.accept_fa2_administrator()

async function changeFA2admin(event) {

    let { initialized, address, error, loading, connect } = getWallet()

    if (initialized) {
        console.log("wallet connected with ", address)
    }

    else {
        console.log(error)
        await getWallet().connect()
    }

    try {
        const FA2Contract = await Tezos.wallet.at(FA2_CONTRACT)

        const newMinterContract = await Tezos.wallet.at(NEW_MINTER)

        //https://tezostaquito.io/docs/batch_api/

        const batch = Tezos.wallet.batch()

        if (!CURRENT_MINTER_AS_ADMIN || CURRENT_MINTER_AS_ADMIN == "") {
            // direct transfer when admin is a wallet
            batch.withContractCall(
                FA2Contract.methods.transfer_administrator(NEW_MINTER)
            )
        }
        else {
            // ask the current contract as admin to transfer
            const currentMinterContract = await Tezos.wallet.at(CURRENT_MINTER_AS_ADMIN)

            batch.withContractCall(
                currentMinterContract.methods.transfer_fa2_administrator(NEW_MINTER)
            )
        }

        batch.withContractCall(
            newMinterContract.methods.accept_fa2_administrator()
        )

        const batchOperation = await batch.send()

        if (await batchOperation.status() === "applied") {
            console.log("batch success")
        } else {
            throw `Transaction error: ${await batchOperation.status()}`
        }

    } catch (error) {
        console.log(error);
    }
}


export function printContractSignature(event) {

    const contractAddress = FA2_CONTRACT

    Tezos.contract
        .at(contractAddress)
        .then((contract) => {
            console.log(`List all contract methods: ${Object.keys(contract.methodsObject)}\n`);
            console.log(
                `Inspect the signature of the 'mint' contract method: ${JSON.stringify(
                    contract.methodsObject.update_collection_operators().getSignature(),
                    null,
                    2
                )}`
            );
        })
        .catch((error) => console.log(`Error: ${JSON.stringify(error, null, 2)}`));
}