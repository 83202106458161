import { Tezos } from './wallet.js'
import { cidIsValid, char2Bytes } from './utils'

export { mintToken, mintMultipleTokens }

const FA2TOKENHOLDER = process.env.FA2TOKENHOLDER
const MINTER = process.env.MINTER
const MARKETPLACE = process.env.MARKETPLACE

let isMinting = false

async function mintMultipleTokens(directoryCid, totalTokens) {

    if (isMinting) return

    isMinting = true

    const params = mintParamsFormater(directoryCid, totalTokens)

    let eventMessage

    try {
        const contract = await Tezos.wallet.at(MINTER)

        const op = await contract.methodsObject
            .mint(params)
            .send()

        await op.confirmation()

        if (await op.status() === "applied") {

            console.log("Minting successfully applied!")
            eventMessage = "mint-applied"

        } else {
            throw `Transaction error: ${await op.status()}`
        }

    } catch (error) {

        console.log(error)
        eventMessage = "mint-failed"
    }

    document.dispatchEvent(
        new CustomEvent(eventMessage,
            {
                detail: {},
                bubbles: true,
                composed: true
            })
    )

    isMinting = false
}

function mintParamsFormater(directoryCid, totalTokens) {

    // {
    //     "total": "nat",
    //     "base": "bytes"
    //     "royalties": "nat"
    //   }

    console.log(directoryCid, totalTokens)

    let base

    try {

        if (cidIsValid(directoryCid)) {

            const baseUrlAsBytes = char2Bytes("ipfs://" + directoryCid + "/")

            console.log(baseUrlAsBytes)

            base = baseUrlAsBytes
        }
        else {
            throw `metadata cid ${i} is not a valid cid`
        }

    } catch (error) {

        console.log(error)
    }

    const params = {
        "total": totalTokens,
        "base": base,
        "royalties": 100
    };

    return params;
}
