import { uploadToNftStorage, uploadMultipleToNftStorage } from "./ipfs-uploader.js"

import { schema } from "./tzip21-metadata-schema.js"

const notEmptyPattern = "[^ 	]+"
const ipfsPattern = "ipfs://Qm[1-9A-HJ-NP-Za-km-z]{44,}|b[A-Za-z2-7]{58,}|B[A-Z2-7]{58,}|z[1-9A-HJ-NP-Za-km-z]{48,}|F[0-9A-F]{50,}"
const walletPattern = "^(tz1|tz2|tz3|KT1)[0-9a-zA-Z]{33}"
// https://en.wikipedia.org/wiki/IETF_language_tag
// https://stackoverflow.com/questions/3962543/how-can-i-validate-a-culture-code-with-a-regular-expression/3962783#3962783
const languageTagPattern = "[a-zA-Z]{1,8}(-[a-zA-Z0-9]{1,8})*"

import Ajv from "ajv"
import addFormats from "ajv-formats"

const ajv = new Ajv()
addFormats(ajv)

ajv.addFormat("tzaddress", walletPattern)
ajv.addFormat("https://tools.ietf.org/html/rfc1766", languageTagPattern)

const validate = ajv.compile(schema)

export { createMetadataForIPFS, uploadMetadataToIPFS, uploadMultipleMetadataToIPFS }

// https://tzip.tezosagora.org/proposal/tzip-21/

function tzip21metadata(info = {
    seriesName: "miniPFP",
    seriesUuid: "9b1deb4d-3b...",
    seriesDescription: "A miniPFP collection of 256 programmatically generated images",
    creatorTzAddress: "tz...",
    fileName: "23",
    cids: { ARTIFACT: "", DISPLAY: "", THUMBNAIL: "" },
    attributes: [
        {
            "name": "Base",
            "value": "Starfish"
        },
        {
            "name": "Eyes",
            "value": "Big"
        }]
}
) {

    let { seriesName, seriesUuid, seriesDescription, creatorTzAddress, fileName, cids, attributes } = info

    const OneIndexedName = (Number.parseInt(fileName, 10) + 1).toString(10)

    this.metadata = {
        name: `${seriesName} #${OneIndexedName}`,
        collectionid: seriesUuid,
        collectionname: seriesName,
        description: seriesDescription,
        tags: ["miniPFP", "PFP", "generative", "gen art", seriesName],
        attributes: attributes,

        rights: "CC BY-NC 4.0",

        symbol: "MINI",
        shouldPreferSymbol: false,

        minter: creatorTzAddress,
        creators: [creatorTzAddress],

        royalties: {
            decimals: 3,
            shares: {
                [creatorTzAddress]: 100,
                // keeping it consistent with contract where user royalties in per mille (100 is 10%
                // using decimals: 3, we have 100 => 0.100
            },
        },
        decimals: 0,
        date: new Date().toISOString(),

        artifactUri: "ipfs://" + cids.ARTIFACT, //fullsize
        displayUri: "ipfs://" + cids.DISPLAY, //display
        thumbnailUri: "ipfs://" + cids.THUMBNAIL, //thumbnail

        formats: [
            {
                dimensions: {
                    unit: "px",
                    value: "2048x2048",
                },
                fileName: fileName + "_artifact.jpg",
                mimeType: "image/jpeg",
                uri: "ipfs://" + cids.ARTIFACT, //fullsize
            },
            {
                dimensions: {
                    unit: "px",
                    value: "1024x1024",
                },
                fileName: fileName + "_display.jpg",
                mimeType: "image/jpeg",
                uri: "ipfs://" + cids.DISPLAY, //display
            },
            {
                dimensions: {
                    unit: "px",
                    value: "256x256",
                },
                fileName: fileName + "_thumbnail.jpg",
                mimeType: "image/jpeg",
                uri: "ipfs://" + cids.THUMBNAIL, //thumbnail
            },
        ],
    };

}

function createMetadataForIPFS(metadataInformations) {

    let { metadata } = new tzip21metadata(metadataInformations)

    const valid = validate(metadata)

    if (!valid) {
        console.log(validate.errors)
        throw "Metadata out of schema: " + JSON.stringify(validate.errors[0])
    }

    return metadata
}

async function uploadMultipleMetadataToIPFS(metadatas = [{}, {}]) {

    let files = []

    for (let i = 0; i < metadatas.length; i++) {

        const file = new File([JSON.stringify(metadatas[i], null, 2)],
            i.toString(10),
            { type: 'application/json' })

        files.push(file)

    }

    const { directoryCid, fileNames } = await uploadMultipleToNftStorage(files)

    console.log(directoryCid, fileNames)

    return { directoryCid, fileNames }
}

async function uploadMetadataToIPFS(metadata) {

    let blob = new Blob([JSON.stringify(metadata, null, 2)], { type: 'application/json' })

    let cid = await uploadToNftStorage(blob);

    return cid

    // // the Tezos FA2 contracts store the full ipfs:// URL as hex encoded bytes
    // return Buffer.from(
    //     "ipfs://" + cid,
    //     "utf8"
    // ).toString("hex");
}