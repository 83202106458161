// Simple PFP layer compositor with direct batch minting transactions

import { uploadAllPFPs, randomPreview } from './compositor.js'
import { mintMultipleTokens } from './contract-caller.js'
import * as fetchGallery from './fetch-gallery.js' // we import fetchGallery for Parcel to bundle it

import * as components from './components.js' // we import the web-components for the side effects, not use it there

self.postMessage({
    type: "cache-gallery-data-locally",
})

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

async function init() {

    setUpDropZone()

    // attachEventsToButtons()

    self.addEventListener(
        "request-random-preview",
        function handleRandomPreviewRequest(event) {

            randomPreview(event.detail.canvascontext, event.detail.maxlayers, event.detail.maxattributes, event.detail.maxpixelsize)

        })

    self.addEventListener(
        'request-upload-all-pfps',
        (event) => {

            uploadAllPFPs(
                event.detail.canvas,
                event.detail.maxLayers,
                event.detail.maxAttributes,
                event.detail.layers,
                event.detail.seriesName,
                event.detail.seriesDescription
            )
        })

    self.addEventListener(
        'metadata-uploaded',
        (event) => {

            console.log("metadata uploaded")

            const createPage = document.getElementsByTagName("create-page")[0]

            if (!createPage) return

            createPage.isMetadataUploaded = true
            createPage.directoryCid = event.detail.directoryCid
            createPage.totalTokens = event.detail.totalTokens
        }
    )

    self.addEventListener(
        'request-mint',
        (event) => {

            mintMultipleTokens(event.detail.directoryCid, event.detail.totalTokens)
        }
    )

}


// https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
// https://developer.mozilla.org/fr/docs/Web/API/File/Using_files_from_web_applications

function setUpDropZone() {

    let dropArea = document.getElementsByTagName('body')[0]

    const eventNames = ['dragenter', 'dragover', 'dragleave', 'drop']
    eventNames.forEach(eventName => {
        dropArea.addEventListener(eventName, preventDefaults, false)
    })

    function preventDefaults(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    const enterEventNames = ['dragenter', 'dragover']
    enterEventNames.forEach(eventName => {
        dropArea.addEventListener(eventName, highlight, false)
    })

    const leaveEventNames = ['dragleave', 'drop']
    leaveEventNames.forEach(eventName => {
        dropArea.addEventListener(eventName, unhighlight, false)
    })

    function highlight(e) {
        dropArea.classList.add('highlight')
    }

    function unhighlight(e) {
        dropArea.classList.remove('highlight')
    }
}

