import { TezosToolkit, compose } from '@taquito/taquito'
import { Tzip16Module, tzip16, bytes2Char } from "@taquito/tzip16"
import { Tzip12Module, tzip12 } from "@taquito/tzip12"

import { db } from './dexie.js'

const Tezos = new TezosToolkit('https://rpc.ghostnet.teztnets.xyz')

Tezos.addExtension(new Tzip16Module())

const FA2TOKENHOLDER = process.env.FA2TOKENHOLDER

let isFetching = false
let isCachingTokens = new Set();

self.addEventListener('message', cacheGalleryMessageHandler)

function cacheGalleryMessageHandler(event) {

  if (event.data.type == "cache-gallery-data-locally" && !isFetching) {
    cacheGalleryDataLocally()
  }

  if (event.data.type == "cache-token-data-locally") {
    if (!isCachingTokens.has(event.data.tokenid)) {
      cacheToken(event.data.tokenid)
    }
  }
}

async function cacheGalleryDataLocally() {

  isFetching = true

  postStatus()

  // console.log(`[gallery] starting to cache`)

  const contract = await Tezos.contract.at(
    FA2TOKENHOLDER,
    compose(tzip16, tzip12))

  let lastTokenIdOnContract
  let currentTokenId = -1
  let previousTokenId

  await contract.tzip16().metadataViews()
    .then((views) => {
      // console.log(`[gallery] The following view names were found in the metadata: ${Object.keys(views)}`)

      return views['last_token_id']().executeView();
    })

    .then((result) => {
      // console.log(`[gallery] Result of the view 'last_token_id': ${result}`)

      // Views Nat are returned as a BigNumber and must be converted to a number
      currentTokenId = lastTokenIdOnContract = result.toNumber()
    })

    .catch(
      error => {
        if (error.message.includes("FA2_NO_TOKEN")) {
          console.log(`[gallery] no token minted on contract`)
          console.log(`[gallery] currentTokenId ${currentTokenId}`)
        }
        else {
          console.log(error)
        }
      }
    );


  while (currentTokenId >= 0) {

    if (currentTokenId == previousTokenId) {
      previousTokenId = currentTokenId
      currentTokenId--
      console.log("[gallery] trying to fetch twice")
      continue
    }

    if (isCachingTokens.has(currentTokenId)) {
      continue
    }

    isCachingTokens.add(currentTokenId)

    // checking if we need to download the metadata at all
    let isMetadataStored = false

    // is the metadata already present?
    await db.metadata.get(currentTokenId)
      .then((tokenMetadata) => {

        if (tokenMetadata) {
          // console.log(`[gallery] metadata for token ${currentTokenId} already stored`)

          saveIpfsImageLocally(tokenMetadata.thumbnailUri)

          isMetadataStored = true
          isCachingTokens.delete(currentTokenId)
          previousTokenId = currentTokenId
          currentTokenId--
        }
      })
      .catch(
        error => {
          console.log(error)
        }
      )

    if (isMetadataStored) continue

    // console.log(`[gallery] fetching metadata for token #${tokenId}`)

    await contract.tzip12()
      .getTokenMetadata(currentTokenId)
      .then(tokenMetadata => {

        db.metadata.add(tokenMetadata)

        saveIpfsImageLocally(tokenMetadata.thumbnailUri)
        // tokenMetadata.displayUri
        // tokenMetadata.artifactUri
      })
      .catch(
        error => {
          if (error.message.includes("FA2_TOKEN_UNDEFINED")) {
            console.log(`[gallery] token ${currentTokenId} doesn't exist`)
          }
          else { console.log(error) }
        }
      )
      .finally(
        () => {
          isCachingTokens.delete(currentTokenId)
          previousTokenId = currentTokenId
          currentTokenId--
        }
      )
    postStatus(false)
  }

  postStatus(true)
  isFetching = false
}

async function cacheToken(currentTokenId) {

  console.log("caching token ", currentTokenId, isCachingTokens)

  isCachingTokens.add(currentTokenId)

  // is the metadata already present?
  const tokenMetadata = await db.metadata.get(currentTokenId)

  if (tokenMetadata) {
    console.log(`[gallery] metadata for token ${currentTokenId} already stored`)
    saveIpfsImageLocally(tokenMetadata.thumbnailUri)
      .then(
        () => {
          postTokenStatus(currentTokenId, true)
        })
  }
  else {

    console.log(`[gallery] metadata for token ${currentTokenId} not stored`)

    const contract = await Tezos.contract.at(
      FA2TOKENHOLDER,
      compose(tzip16, tzip12))

    try {
      const tokenMetadata = await contract.tzip12().getTokenMetadata(currentTokenId) // this Taquito function use ipfs.io by default. 

      db.metadata.add(tokenMetadata)

      await saveIpfsImageLocally(tokenMetadata.thumbnailUri)

      postTokenStatus(currentTokenId, true)
    }

    catch (error) {
      if (error.message.includes("FA2_TOKEN_UNDEFINED")) {
        // console.log(`[gallery] token ${currentTokenId} doesn't exist`)
        postTokenStatus(currentTokenId, false)
      }
      else {
        console.log(error)
        postTokenStatus(currentTokenId, false)
      }
    }
  }

  console.log("finished caching token ", isCachingTokens)

  isCachingTokens.delete(currentTokenId)
}

// utils

async function fetchByIpfsUri(ipfsUri = "ipfs://bafkreihqpostcjo25pwvwfzlafg33ebwh53267cpzhv6xhbpi3b7m3kk4e") {

  const ipfsGateways = [
    "https://cloudflare-ipfs.com/ipfs/",
    "https://nftstorage.link/ipfs/",
    "https://ipfs.fleek.co/ipfs/",
  ]

  const thumbnailURLs = ipfsGateways.map(gateway => ipfsUri.replace("ipfs://", gateway))

  return fetchWithFallbacks(thumbnailURLs)

}

async function fetchWithFallbacks(urls) {

  const url = urls.shift()

  return fetch(url)
    .then(response => {
      if (response.ok) {
        // console.log("[gallery] fetch OK")
        return response;
      }

      throw new Error(response.status)
    })
    .catch(
      error => {
        console.error(error.message)
        if (urls.length > 0 && urls[0]) {
          // console.log("[gallery] fetch fallback")
          return fetchWithFallbacks(urls)
        }
      }
    )

}

async function saveIpfsImageLocally(ipfsUri) {

  if (await db.images.get(ipfsUri)) {
    // console.log(`[gallery] image in store for ${ipfsUri}`)
    return
  }

  // console.log(`[gallery] fetching image ${ipfsUri}`)

  return fetchByIpfsUri(ipfsUri)

    .then(function (response) {
      return response.blob();
    })

    .then(function (blob) {

      return db.images.put({
        ipfsUri: ipfsUri,
        image: blob
      });

    })

    .catch(
      error => {
        console.log("[gallery]", error)
        return Promise.reject(error)
      }
    )
}

async function postStatus(allFetched = false) {

  db.metadata
    .toCollection()
    .primaryKeys()
    .then((keys) => {
      self.postMessage({
        type: "gallery-status",
        length: keys.length,
        keys: keys,
        allFetched: allFetched
      })
    })
    .catch((error) => {
      console.log(error);
    })
}

async function postTokenStatus(tokenId, tokenExists) {

  const tokenIdAsInt = Number.parseInt(tokenId, 10)

  if (Number.isInteger(tokenIdAsInt)) {

    console.log(`[gallery] posting token ${tokenId} exists: ${tokenExists}`)

    self.postMessage({
      type: "token-status",
      tokenid: tokenIdAsInt,
      exists: tokenExists,
    })

  }
}