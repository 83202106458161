// Simple PFP layer compositor with direct batch minting transactions

import { BaseN } from 'js-combinatorics'
import { randomIntUpTo, releaseCanvas, storageAvailable } from './utils.js'
import './createimagebitmap-polyfill.js'
import { uploadToNftStorage } from "./ipfs-uploader.js"
import { createMetadataForIPFS, uploadMultipleMetadataToIPFS } from "./metadata-creator.js"
import { getWallet } from "./wallet.js"
import * as localforage from 'localforage'
import blobToHash from 'blob-to-hash'
import { v4 as uuidv4 } from 'uuid';

export { uploadAllPFPs, generatePFP }

const SANDBOX = false

const GENERATED_PIXEL_SIZE = 2048

const IMAGEFORMAT = {
  "ARTIFACT": "ARTIFACT",
  "DISPLAY": "DISPLAY",
  "THUMBNAIL": "THUMBNAIL"
}

let METADATAS = []

let isUploading = false

async function uploadAllPFPs(canvas, maxLayers = 4, maxAttributes = 4, layers = [], seriesName = "miniPFP", seriesDescription = "256 generated images") {

  if (isUploading) return

  isUploading = true

  const ctx = canvas.getContext("2d")

  let base = []

  for (let digit = 0; digit < maxAttributes; digit++) {

    base.push(digit)

  }

  let allPossibilities = [... new BaseN(base.join(""), maxLayers)]

  shuffle(allPossibilities)

  // probably from https://stackoverflow.com/a/12646864 
  // shuffle in place the array
  // nice one-line destructuring swap, instead of 3 lines 
  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  console.log(allPossibilities)

  if (allPossibilities.length != Math.pow(maxAttributes, maxLayers)) {
    console.log("BaseN mismatch", allPossibilities.length, Math.pow(maxAttributes, maxLayers))
    return
  }

  // preview fitting calculations
  const previewPerSide = Math.sqrt(allPossibilities.length) // sqrt(256) = 16
  const previewSize = ctx.canvas.width / previewPerSide // 2048 / 16 = 128  
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

  let pfpToGenerate = allPossibilities.length

  if (SANDBOX) { pfpToGenerate = 3 }

  const { address: userAddress } = await getWallet().connect()
  console.log("creator address: ", userAddress);

  // const userAddress = "tz1ahsDNFzukj51hVpW626qH7Ug9HeUVQDNG"

  const seriesUuid = uuidv4() // '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'

  METADATAS = []

  for (let pfp = 0; pfp < pfpToGenerate; pfp++) {

    // Preview spot on the grid
    const spotX = pfp % previewPerSide
    const spotY = Math.floor(pfp / previewPerSide)

    // Preview pixel position
    const posX = spotX * previewSize
    const posY = spotY * previewSize

    const selectedAttributesForEachLayer = allPossibilities[pfp]

    await generatePFP(selectedAttributesForEachLayer, GENERATED_PIXEL_SIZE)
      .then(
        async (generatedCanvas) => {

          // previewing the progression

          ctx.drawImage(generatedCanvas, posX, posY, previewSize, previewSize)

          // if we can verify that we already uploaded this exact images, 
          // by getting the hash from the localstore
          // skip the uploading and retrieve the cids from local storage

          let fileName = pfp.toString()

          let nameAndCids

          const imageHash = await blobToHash(
            'sha256',
            await canvasToBlob(generatedCanvas, "image/png", 1))


          let compositorStore = localforage.createInstance({
            name: "compositor",
            storeName: "nameandcids",
            description: "miniPFP nft name and cids"
          })

          const value = await compositorStore
            .getItem(imageHash)
            .catch((error) => {
              console.log(error);
            })

          if (value && value[IMAGEFORMAT.ARTIFACT] && value[IMAGEFORMAT.DISPLAY] && value[IMAGEFORMAT.THUMBNAIL]) {
            nameAndCids = value
            nameAndCids.fileName = fileName
          }
          else {
            let cids = await uploadImageVariants(generatedCanvas)
            nameAndCids = { fileName, ...cids }
            await compositorStore.setItem(imageHash, nameAndCids)
          }

          releaseCanvas(generatedCanvas)

          return nameAndCids
        }
      )
      .then(
        async (cids) => {
          console.log(cids)

          if (cids && cids[IMAGEFORMAT.ARTIFACT] && cids[IMAGEFORMAT.DISPLAY] && cids[IMAGEFORMAT.THUMBNAIL] && cids.fileName) {
            try {

              let info = {
                seriesName: seriesName,
                seriesUuid: seriesUuid,
                seriesDescription: seriesDescription,
                creatorTzAddress: userAddress,
                fileName: cids.fileName,
                cids: {
                  ARTIFACT: cids[IMAGEFORMAT.ARTIFACT],
                  DISPLAY: cids[IMAGEFORMAT.DISPLAY],
                  THUMBNAIL: cids[IMAGEFORMAT.THUMBNAIL]
                },
                attributes: []
              }

              for (let layerIndex = 0; layerIndex < selectedAttributesForEachLayer.length; layerIndex++) {

                info.attributes.push(

                  {
                    name: layers[layerIndex].userFacingName,
                    value: layers[layerIndex].attributes[selectedAttributesForEachLayer[layerIndex]].userFacingName
                  }

                )

              }

              console.log(info.attributes)

              const metadata = createMetadataForIPFS(info)

              METADATAS.push(metadata)

              ctx.fillStyle = "rgba(193, 254, 193, 127)"
              ctx.fillRect(posX, posY, previewSize, previewSize * 0.05)
            }
            catch (error) {
              console.error(error)
              ctx.fillStyle = "rgba(255, 0, 0, 127)"
              ctx.fillRect(posX, posY, previewSize, previewSize * 0.05)
            }
          }
          else {
            console.error("incomplete cids: ", cids)
            ctx.fillStyle = "rgba(255, 0, 0, 127)"
            ctx.fillRect(posX, posY, previewSize, previewSize * 0.05)
          }
        }
      )
  }

  const { directoryCid, fileNames } = await uploadMultipleMetadataToIPFS(METADATAS)

  console.log(METADATAS, directoryCid, fileNames)

  let myEvent = new CustomEvent('metadata-uploaded', {
    detail: {
      directoryCid: directoryCid,
      totalTokens: fileNames.length,
    },
    bubbles: true,
    composed: true
  })
  self.dispatchEvent(myEvent)

  isUploading = false

}

// uploadImageVariants creates the three recommended TZ21 metadata variants (artifact, display and thumbnail) 

async function uploadImageVariants(canvas) {

  let cids = {
    // [IMAGEFORMAT.ARTIFACT],
    // [IMAGEFORMAT.DISPLAY],
    // [IMAGEFORMAT.THUMBNAIL],
  }

  await canvasToBlob(canvas, "image/jpeg", 0.9) // max size
    .then(uploadToNftStorage)
    .then((cid) => { cids[IMAGEFORMAT.ARTIFACT] = cid })

  await resizeImage(canvas, { width: 1024, height: 1024, pixelArt: true })
    .then((canvas) => { return canvasToBlob(canvas, "image/jpeg", 0.9) })
    .then(uploadToNftStorage)
    .then((cid) => { cids[IMAGEFORMAT.DISPLAY] = cid })

  await resizeImage(canvas, { width: 256, height: 256, pixelArt: true })
    .then((canvas) => { return canvasToBlob(canvas, "image/jpeg", 0.9) })
    .then(uploadToNftStorage)
    .then((cid) => { cids[IMAGEFORMAT.THUMBNAIL] = cid })

  releaseCanvas(canvas)

  return cids

  ///////////////////// uploadImageVariants internal utils functions ///////////////////

  async function resizeImage(image, options = { width: 350, height: 350, pixelArt: false }) {

    let canvas = document.createElement("canvas")

    canvas.width = options.width
    canvas.height = options.height

    let ctx = canvas.getContext("2d")

    if (options.pixelArt) { ctx.imageSmoothingEnabled = false }

    ctx.drawImage(image, 0, 0, canvas.width, canvas.height)

    return canvas

  }

}


async function generatePFP(attributes = [0, 0, 0, 0], size) {

  let canvas = document.createElement("canvas")

  canvas.width = size
  canvas.height = size

  let ctx = canvas.getContext("2d")

  for (let layer = 0; layer < attributes.length; layer++) {

    // this is brittle and coupling, as we are depending on the components to not change their path
    // and of course piercing the shadow DOMs is contrary to the reasons for their existence
    // instead we could pass the references to the files to use

    const selectedFile = document.querySelector("#contentcontainer > create-page").shadowRoot.querySelector("layers-form").shadowRoot.getElementById(`layer${layer}attribute${attributes[layer]}File`)

    const myImageFile = selectedFile.files[0]

    if (myImageFile) {
      const imageBitmap = await createImageBitmap(
        myImageFile,
        {
          premultiplyAlpha: 'none',
          colorSpaceConversion: 'none',
          resizeWidth: size,
          resizeHeight: size,
          resizeQuality: "pixelated"
        }
      )
      ctx.drawImage(imageBitmap, 0, 0, ctx.canvas.width, ctx.canvas.height)
    }
  }

  return canvas
}

export async function randomPreview(CTX, MAX_LAYERS, MAX_ATTRIBUTES, MAX_PIXEL_SIZE) {

  const randomAttributes = []

  for (let i = 0; i < MAX_LAYERS; i++) {
    randomAttributes.push(randomIntUpTo(MAX_ATTRIBUTES - 1))
  }

  const randomCanvas = await generatePFP(randomAttributes, MAX_PIXEL_SIZE)

  CTX.drawImage(randomCanvas, 0, 0, CTX.canvas.width, CTX.canvas.height)

  releaseCanvas(randomCanvas)
}


///////////////////// image manipulation utils functions ///////////////////

function canvasToBlob(canvas, type, quality) {
  return new Promise(function (resolve) {
    canvas.toBlob(resolve, type, quality)
  })
}