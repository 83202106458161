// Utility functions

export {
    randomIntUpTo, releaseCanvas, storageAvailable,
    hex2buf, cidIsValid, bytes2Char, char2Bytes
}

function randomIntUpTo(max) {
    return Math.floor(Math.random() * max + 1);
}

// Safari cleanup https://pqina.nl/blog/total-canvas-memory-use-exceeds-the-maximum-limit/
function releaseCanvas(canvas) {
    canvas.width = 1;
    canvas.height = 1;
    const ctx = canvas.getContext('2d');
    ctx && ctx.clearRect(0, 0, 1, 1);
}


// detects whether localStorage is both supported and available
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API

function storageAvailable(type) {
    var storage;
    try {
        storage = window[type];
        var x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0);
    }
}

/// utils // 

function hex2buf(hex) {
    const match = hex.match(/[\da-f]{2}/gi);
    if (match) {
        return new Uint8Array(match.map((h) => parseInt(h, 16)));
    } else {
        throw new ValueConversionError(hex, 'Uint8Array');
    }
}

function cidIsValid(cid) {

    // https://stackoverflow.com/questions/67176725/a-regex-json-schema-pattern-for-an-ipfs-cid
    // not keen just yet on using a lib just for validating

    return /^(Qm[1-9A-HJ-NP-Za-km-z]{44,}|b[A-Za-z2-7]{58,}|B[A-Z2-7]{58,}|z[1-9A-HJ-NP-Za-km-z]{48,}|F[0-9A-F]{50,})$/.test(cid)

}

/**
 * @description Convert a string to bytes
 *
 * @param string String to convert
 */

function char2Bytes(string) {
    return Buffer.from(string, 'utf8').toString('hex');
}

/**
 * @description Convert bytes to a string
 *
 * @param hex Bytes to convert
 */

function bytes2Char(hex) {
    return Buffer.from(hex2buf(hex)).toString('utf8');
}