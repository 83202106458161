import { create } from "ipfs-http-client";
const ipfsClient = create({ url: "https://ipfs.infura.io:5001/api/v0" });

// import { NFTStorage } from 'nft.storage'
import { NFTStorage } from '../node_modules/nft.storage/dist/bundle.esm.min.js'

const nftStorageClient = new NFTStorage({ token: process.env.NFT_STORAGE_TOKEN })

export { uploadMultipleToIPFS, uploadToIPFS, uploadMultipleToNftStorage, uploadToNftStorage, }

async function uploadMultipleToIPFS(files) {
    console.log(files)
    let added = [];
    try {
        for await (const result of ipfsClient.addAll(files, {
            wrapWithDirectory: true,
            pin: true,
        })) {
            console.log(result);
            added.push(result);
        }

        if (files.length !== added.length) { throw "IPFS Failed to return content IDs for all the uploaded files. Retry" }

        return added;
    } catch (error) {
        console.log("Error uploading file: ", error);
        return added;
    }
}

async function uploadToIPFS(file) {
    console.log(file)
    try {
        let result = await ipfsClient.add(file, {
            wrapWithDirectory: false,
            pin: true,
        })

        if (!result) { throw "IPFS Failed to return content IDs for all the uploaded files. Retry" }

        return result;

    } catch (error) {
        console.log("Error uploading file: ", error);

        return error;
    }
}

async function uploadMultipleToNftStorage(files = [new File(['hello world'], 'hello')]) {

    try {

        const directoryCid = await nftStorageClient.storeDirectory(files)

        const status = await nftStorageClient.status(directoryCid)
        console.log(status)

        let fileNames = []

        for (let file of files) {

            fileNames.push(file.name)

        }

        return { directoryCid, fileNames }

    } catch (error) {
        console.log("Error uploading directory to nft.storage: ", error);
    }
}

async function uploadToNftStorage(blob) {

    try {

        const blobCid = await nftStorageClient.storeBlob(blob)

        const status = await nftStorageClient.status(blobCid)
        console.log(status)

        return blobCid

    } catch (error) {
        console.log("Error uploading file to nft.storage: ", error);
    }

}
