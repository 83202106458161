import { TezosToolkit } from '@taquito/taquito'
import { BeaconWallet } from '@taquito/beacon-wallet'

// alternative RPC nodes from https://tezostaquito.io/docs/rpc_nodes/
// SmartPy Mainnet https://mainnet.smartpy.io
// Tezos Foundation Mainnet https://rpc.tzbeta.net/
// madfish is the one used by default by Temple wallet

// const Tezos = new TezosToolkit('https://mainnet-node.madfish.solutions');
// const network = 'mainnet'

const Tezos = new TezosToolkit('https://rpc.ghostnet.teztnets.xyz')
const network = 'ghostnet'

import { BeaconEvent, defaultEventCallbacks } from '@airgap/beacon-dapp'

export { getWallet, Tezos }

const options = {
    name: 'miniPFP',
    iconUrl: 'https://pbs.twimg.com/profile_images/1519642383339634688/6hYq88cq_400x400.jpg',
    preferredNetwork: network,
    eventHandlers: {
        PERMISSION_REQUEST_SUCCESS: {
            handler: async (data) => {
                console.log('wallet permission granted, data:', data)

                self.postMessage({
                    type: "wallet-permission-granted",
                    data: data,
                })

                defaultEventCallbacks[BeaconEvent.PERMISSION_REQUEST_SUCCESS](data)
            },
        },
    },
}

let initialized = false
let address = undefined
let error
let loading = false

function getWallet() {

    return { initialized, address, error, loading, connect }

    async function connect() {
        try {
            console.log("let init the wallet")

            loading = true

            const walletaddress = await initWallet()
            console.log(walletaddress)

            initialized = true
            address = walletaddress

            console.log(
                initialized,
                address,
                error,
                loading
            )
        } catch (error) {
            error = error.message
        } finally {
            loading = false;
        }

        return {
            initialized,
            address,
            error,
            loading
        }
    }

    async function initWallet() {

        const wallet = new BeaconWallet(options);

        console.log("connecting wallet")

        await wallet.requestPermissions({ network: { type: network } })

        Tezos.setWalletProvider(wallet)

        return await wallet.getPKH()
    }
}
